<template>
  <div>
    <template v-if="data.is_array && data.enum.length">
      <v-select v-model="setting" :items="data.enum" @change="editSettings" :multiple="data.is_array" outlined dense />
    </template>
    <template v-else>
      <template v-if="data.type === 'string'">
        <v-text-field v-model="setting" @input="editSettings" outlined dense></v-text-field>
      </template>
      <template v-if="data.type === 'integer' || data.type === 'double'">
        <v-text-field
          v-model="setting"
          @input="editSettings"
          :error-messages="fieldErrors"
          @blur="$v.setting.$touch()"
          outlined
          dense
        ></v-text-field>
      </template>
      <template v-if="data.type === 'boolean'">
        <v-select
          :items="userRegisterItems"
          v-model="setting"
          @change="editSettings"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>
      </template>
      <template v-if="data.type === 'file'">
        <v-img v-if="showImage" :src="`${baseURL}/${setting}`" alt="image_data" width="100" height="100" aspect-ratio="1" />
        <span v-else style="font-size: 11px; margin-bottom: 4px">Картинку буде видно після завантаження на сервер</span>
        <FileUploader accept="image/*" label="Завантажити лого" prepend-icon="mdi-camera" return-binary @encode="encodeFile" />
      </template>
    </template>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { integer, decimal } from 'vuelidate/lib/validators'
import FileUploader from '@/components/common/FileUploader.vue'

export default {
  name: 'SettingsControl',
  components: { FileUploader },

  mixins: [validationMixin],

  validations: {
    setting: { integer, decimal },
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    setting: null,
    userRegisterItems: [
      {
        title: 'Вимкнена',
        value: false,
      },
      {
        title: 'Увімкнена',
        value: true,
      },
    ],
    showImage: true,
  }),

  computed: {
    fieldErrors() {
      const errors = []
      if (!this.$v.setting.$dirty) return errors
      switch (this.data.type) {
        case 'integer': {
          !this.$v.setting.integer && errors.push('Enter integer')
          break
        }
        case 'decimal': {
          !this.$v.setting.decimal && errors.push('Enter decimal')
        }
      }
      return errors
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.setting = this.data.actual_value
    },
    editSettings() {
      this.$v.setting.$touch()
      const valueToEmit = Array.isArray(this.setting) ? JSON.stringify(this.setting) : this.setting
      this.$emit('editSettings', this.data.key, valueToEmit)
    },
    encodeFile(data) {
      this.setting = data
      this.showImage = false
      this.$v.setting.$touch()
      this.$emit('editSettings', this.data.key, this.setting)
    },
  },
}
</script>

<style scoped></style>
